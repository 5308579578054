<template>
  <v-container class="pa-4" fluid>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <!-- <v-responsive :aspect-ratio="16/9"> -->
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                v-show="!bookingDetails.hasOwnProperty('zone')"
                height="260px"
                class="rounded-t-lg"
                type="image"
              ></v-skeleton-loader>
               -->
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="
              bookingDetails.hasOwnProperty('zone') && bookingDetails.zone.image != '' && bookingDetails.zone.image != null
                ? bookingDetails.zone.image
                : zoneImgURL
            " :lazy-src="
  bookingDetails.hasOwnProperty('zone') && bookingDetails.zone.image != '' && bookingDetails.zone.image != null
    ? bookingDetails.zone.image
    : zoneImgURL
" alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="thankyou_header white--text text-left mr-4">
              Thank you!
            </div>
          </div>
          <v-card-title class="pa-1"></v-card-title>
          <v-card-text class="pa-2">

            <v-row align="center" class="mx-0 pb-2" no-gutters> </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Mobile No:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    bookingDetails.hasOwnProperty("user") &&
                    bookingDetails.user.hasOwnProperty("contact")
                    ? bookingDetails.user.contact
                    : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Plate:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    bookingDetails.hasOwnProperty("booking") &&
                    bookingDetails.booking.hasOwnProperty("vehicleNo")
                    ? bookingDetails.booking.vehicleNo.toUpperCase()
                    : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Start Date:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    bookingDetails.hasOwnProperty("booking") &&
                    bookingDetails.booking.hasOwnProperty("revStart")
                    ? formatStartEndDate(bookingDetails.booking.revStart)
                    : "-"
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header">End Date:</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date">
                  {{
                    bookingDetails.hasOwnProperty("booking") &&
                    bookingDetails.booking.hasOwnProperty("revEnd")
                    ? formatStartEndDate(bookingDetails.booking.revEnd)
                    : "-"
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row class="justify-center mb-3" no-gutters v-if="Object.hasOwnProperty.call(bookingDetails, 'booking') && Object.hasOwnProperty.call(bookingDetails.booking, 'TypeOfBooking') && bookingDetails.booking.TypeOfBooking == 'P2p'">
              <span class="font-weight-bold black--text">Your transaction at  {{ bookingDetails.hasOwnProperty("zone") &&
                  bookingDetails.zone.hasOwnProperty("zoneName")
                  ? bookingDetails.zone["zoneName"]
                  : "" }} was moved to exception.</span>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CheckIn",
  components: {},
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      bookingId: "getterBookingId",
      bookingDetails: "getterBookingDetails",
    })
  },
  watch: {},
  created() { },
  mounted() {
  },
  destroyed() { },
  // beforeRouteEnter(to, from, next) {
  //   next(async (vm) => {
  // },
  methods: {
    formatStartEndDate(time){
      return this.formatDateTime(time, 'date')  
    },
    formatDateTime(time, val) {
      if (time !== null && time !== undefined && time != "") {
        let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
        let month = utcDate.split(",")[1].split(" ")[2]; // Jan
        let date = utcDate.split(",")[1].split(" ")[1]; // 27
        let year = utcDate.split(",")[1].split(" ")[3];
        let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
        let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
        let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
        formatHr = Number(hr) == 0 ? 12 : formatHr;

        formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
        let amOrpm = Number(hr) >= 12 ? "p" : "a"; //PM
        let ampm = Number(hr) >= 12 ? "PM" : "AM";
        switch (val) {
          case "hour": {
            return formatHr + amOrpm;
          }
          case "mins": {
            return min;
          }
          case "date": {
            return (
              month +
              " " +
              date +
              ", " +
              year +
              " " +
              formatHr +
              ":" +
              min +
              " " +
              ampm
            );
          }
          case "expire": {
            return (
              formatHr + ":" + min + " " + ampm + " on " + month + " " + date
            );
          }
          case "checkin": {
            return (
              formatHr + ":" + min + " " + ampm + " on " + month + " " + date
            );
          }
        }
      }
      //Jan 27, 01:59 PM
    },
  }
};
</script>
<style lang="less">
.booking_header {
  position: absolute;
  bottom: 60px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}

.booking_divider_div {
  position: absolute;
  bottom: 45px;
  width: 100%;
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.booking_content {
  position: absolute;
  bottom: 18px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.thankyou_header {
  position: absolute;
  bottom: 20px;
  left: 16px;
  font-size: 24px;
  font-weight: bolder;
}
</style>
